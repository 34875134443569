import React from 'react';
import "./Testimonials.scss";

import testimonial1 from "../../../assets/images/testimonial1.png";
import testimonial2 from "../../../assets/images/testimonial2.png";
import testimonial4 from "../../../assets/images/testimonial4.png";

const Testimonials = () => {
  return (
    <>
    <div className="testimonial-wrapper">
        <div className="testimonial-container">
            <div className="testimonial-heading">
                <p className='semibold'>Testimonials</p>
            </div>
            <div className="logos">
                <img src={testimonial1} className='logo' alt="astriq interiors, complete website & social launch kit" />
                <img src={testimonial2} className='logo' alt="trust millennia, complete website & social launch kit" />
                <img src={testimonial4} className='logo' alt="rysngmedia, complete website & social launch kit" />
            </div>
        </div>
    </div>
    </>
  )
}

export default Testimonials;